let jQuery = window['jQuery'];
namespace TotalPoll {
    export var Polls = {};

    /**
     * Utils
     */
    class Modal {
        public element;
        public template = `
<style type="text/css">
    @-webkit-keyframes modalFadeIn {
        from {
            opacity: 0;
        }   
        
        to {
            opacity: 1;
        } 
    }
        
    @keyframes modalFadeIn {
        from {
            opacity: 0;
        }   
        
        to {
            opacity: 1;
        } 
    }
        
    @-webkit-keyframes modalFadeOut {
        from {
            opacity: 1;
        }   
        
        to {
            opacity: 0;
        } 
    }
        
    @keyframes modalFadeOut {
        from {
            opacity: 1;
        }   
        
        to {
            opacity: 0;
        } 
    }
    
    .totalpoll-modal {
        position:fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999999999;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        display: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    
    .totalpoll-modal.totalpoll-is-visible {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-animation: modalFadeIn 0.2s ease-in forwards;
                animation: modalFadeIn 0.2s ease-in forwards;
    }    
    
    .totalpoll-modal.totalpoll-is-hiding {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-animation: modalFadeOut 0.2s ease-in forwards;
                animation: modalFadeOut 0.2s ease-in forwards;
    }
    
    .totalpoll-modal-content {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
            -ms-flex: 1;
                flex: 1;
        width: 100%;
        height: 100%;
        max-width: 100vh;
        max-width: 100%;
        max-height: 100vh;
        max-height: 100%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    
    @media (min-width:768px){
        .totalpoll-modal-content {
            max-width: 85vh;
            max-width: 85%;
            max-height: 85vh;
            max-height: 85%;
            padding: 2em;
        }
    }
    
    .totalpoll-modal-content img {
        max-width: 100%!important;
        max-height: 100%!important;
        width: auto!important;
        height: auto!important;
    }
        
    .totalpoll-modal-content .totalpoll-embed-type-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin: auto;
        text-align: center;
    }
    
    .totalpoll-modal-content .totalpoll-embed-type-video {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        width: 100%;
    }
    
    .totalpoll-modal-content .totalpoll-embed-type-video iframe, .totalpoll-modal-content .totalpoll-embed-type-video object, .totalpoll-modal-content .totalpoll-embed-type-video embed,.totalpoll-modal-content .totalpoll-embed-type-video video, .totalpoll-modal-content .totalpoll-embed-type-video audio {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .totalpoll-modal-close {
        position: absolute;
        top: 1em;
        right: 1em;
        width: 3em;
        height: 3em;
        padding: 1em;
        border: 1px solid rgba(255, 255, 255, 0.1);
        -webkit-border-radius: 50%;
                border-radius: 50%;
        fill: white;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    
    .totalpoll-modal-close svg {
        vertical-align: top;
        opacity: 0.5;
        -webkit-transition: 0.2s all ease-out;
        -o-transition: 0.2s all ease-out;
        transition: 0.2s all ease-out;
    }
    
    .totalpoll-modal-close:hover svg {
        opacity: 1;
        -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
                transform: scale(1.1);
    }
    
    .totalpoll-modal-close:active svg {
        -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
                transform: scale(0.8);
    }
    
</style>
<div class="totalpoll-modal">
    <div class="totalpoll-modal-content"></div>
    <div class="totalpoll-modal-close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 14 14"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>
    </div>
</div>`;

        constructor() {
            this.create();
            this.appendToBody();

            this.element.on('click', (event) => {
                if (jQuery(event.srcElement).hasClass('totalpoll-modal') || jQuery(event.srcElement).hasClass('totalpoll-modal-content')) {
                    this.hide();
                }
            });

            this.element.find('.totalpoll-modal-close').on('click', () => {
                this.hide();
            });
        }

        appendToBody() {
            jQuery(document.body).append(this.element);
        }

        create() {
            this.element = jQuery(this.template);
        }

        hide() {
            this.element.removeClass('totalpoll-is-visible');
            this.element.addClass('totalpoll-is-hiding');
            jQuery(document).unbind('keydown.totalpoll-modal');
            setTimeout(() => {
                this.element.removeClass('totalpoll-is-hiding');
                document.documentElement.style.overflowY = 'initial';
                this.element.find('.totalpoll-modal-content').html('');
            }, 200);
        }

        remove() {
            this.element.remove();
        }

        setContent(content) {
            this.element.find('.totalpoll-modal-content').html(content);
        }

        show() {
            document.documentElement.style.overflowY = 'hidden';
            this.element.addClass('totalpoll-is-visible');
            jQuery(document).one(
                'keydown.totalpoll-modal',
                (event) => {
                    if (event.which === 27) {
                        this.hide();
                    }
                }
            );
        }
    }

    /**
     * Behaviours
     */
    interface Behaviour {
        destroy();
    }

    class AjaxBehaviour implements Behaviour {
        private loader = jQuery(`
            <style>
            .totalpoll-wrapper { 
                position: relative; 
            }
            
            @-webkit-keyframes totalpoll-fade-in {
                form {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            
            @keyframes totalpoll-fade-in {
                form {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            
            .totalpoll-form-loading {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                        flex-direction: column;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                    -ms-flex-align: center;
                        align-items: center;
                background-color: rgba(255,255,255, 0.3);
                opacity: 0;
                -webkit-animation: totalpoll-fade-in 300ms ease-in forwards;
                        animation: totalpoll-fade-in 300ms ease-in forwards;
            }
            </style>
<div class="totalpoll-form-loading">
    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000000">
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"/>
            </path>
        </g>
    </g>
</svg>
</div>`);

        constructor(private poll: Poll) {
            poll.element.find('[type="submit"]').on('click', function (event) {
                poll.element.find('[name="totalpoll[action]"]').val(this.value);
            });
            poll.element.find('form').on('submit', (event) => {
                this.load(this.poll.config.ajaxEndpoint, new FormData(event.currentTarget), 'POST');
                event.preventDefault();
            });
        }

        destroy() {
        }

        load(url, data = {}, method = 'GET') {
            this.poll.element.css('pointer-events', 'none');
            this.poll.element.parent().css('min-height', this.poll.element.parent().outerHeight());

            this.poll.element.prepend(this.loader);

            this.poll.transition.out(() => {

                jQuery.ajax({
                    url: url,
                    data: data,
                    processData: method != 'POST',
                    contentType: false,
                    type: method,
                })
                    .done((response) => {
                        let $poll = jQuery(response);
                        $poll.find('.totalpoll-container').hide();

                        this.poll.transition.out(() => {
                            this.poll.element.replaceWith($poll);
                            let poll = new TotalPoll.Poll($poll, true, this.poll.config['behaviours']['async']);
                            poll.transition.in(() => {
                                poll.element.parent().css('min-height', 0);
                            });
                        });
                    })
                    .fail((error) => {
                        this.poll.element.removeAttr('style');
                        alert(this.poll.config['i18n']['Something went wrong! Please try again.']);
                    });
            });
        }
    }

    class SliderBehaviour implements Behaviour {
        public $buttons;
        public $currentSlide;
        public $navigationButtons;
        public $nextButton;
        public $previousButton;
        public $slides;
        public $voteButton;
        public $resultsButton;
        public hasCustomFields = false;
        public currentSlide = 0;

        //@PRO
        constructor(private poll: Poll) {
            if (poll.screen === 'vote' || poll.screen === 'results') {
                poll.element.find('.totalpoll-form-custom-fields').attr('totalpoll-valid-selection', 'true');
                this.setupButtons(poll.element.find('.totalpoll-buttons'), poll.element.find('.totalpoll-button'));
                this.setupSlides(poll.element.find('.totalpoll-question, .totalpoll-form-custom-fields:has(input)'));
            }
        }

        destroy() {
        }

        isFirstSlide() {
            return this.currentSlide === 0;
        }

        isLastSlide() {
            return this.$slides.length === this.currentSlide + 1;
        }

        isValidSelection() {
            return this.$currentSlide.is('[totalpoll-valid-selection="true"]');
        }

        nextSlide(event) {
            if (this.isLastSlide()) {
                return;
            } else {
                event.preventDefault();
                this.setSlide(this.currentSlide + 1);
            }

        }

        previousSlide(event) {
            if (this.isFirstSlide()) {
                return;
            } else {
                event.preventDefault();
                this.setSlide(this.currentSlide - 1);
            }
        }

        refreshButtons() {
            this.$buttons.hide();
            this.$nextButton[this.isLastSlide() || (this.poll.behaviours['oneClick'] && this.poll.screen === 'vote') ? 'hide' : 'show']();
            this.$previousButton[this.isFirstSlide() || (this.poll.behaviours['oneClick'] && this.poll.screen === 'vote') ? 'hide' : 'show']();
            this.$buttons[this.isLastSlide() && (!this.poll.behaviours['oneClick'] || this.poll.screen === 'vote') ? 'show' : 'hide']();

            if (this.poll.screen === 'vote') {
                this.$voteButton.prop('disabled', !this.isValidSelection());
                this.$nextButton.prop('disabled', !this.isValidSelection());

                if (this.poll.behaviours['oneClick']) {
                    this.$voteButton[this.hasCustomFields && this.isLastSlide() ? 'show' : 'hide']();
                    this.$resultsButton.show();
                }
            }
        }

        setSlide(index) {
            this.$slides.eq(this.currentSlide).fadeOut(500, () => {
                this.currentSlide = index;
                this.$currentSlide = this.$slides.eq(this.currentSlide);
                this.$currentSlide.fadeIn();
                this.refreshButtons();
            });
        }

        setupButtons(container, buttons) {
            this.$buttons = buttons;
            this.$voteButton = this.$buttons.filter('button[value="vote"]');
            this.$resultsButton = this.$buttons.filter('button[value="results"]');

            this.$previousButton = jQuery(`<button type="button" class="totalpoll-button totalpoll-buttons-slider-previous">${this.poll.config['i18n']['Previous']}</button>`);
            this.$nextButton = jQuery(`<button type="button" class="totalpoll-button totalpoll-buttons-slider-next">${this.poll.config['i18n']['Next']}</button>`);

            container.prepend(this.$previousButton);
            container.append(this.$nextButton);

            if (this.poll.behaviours['oneClick'] && this.poll.screen === 'vote') {
                if (this.$resultsButton) {
                    this.$resultsButton.show();
                }

                this.$voteButton.hide();
                this.$previousButton.hide();
                this.$nextButton.hide();
            }

            this.$voteButton.on('click', (event) => this.nextSlide(event));
            this.$previousButton.on('click', (event) => this.previousSlide(event));
            this.$nextButton.on('click', (event) => this.nextSlide(event));
        }

        setupSlides(slides) {
            this.hasCustomFields = slides.is('.totalpoll-form-custom-fields');
            this.$slides = slides;
            this.$slides.hide();

            let $questions = this.$slides.filter('.totalpoll-question');
            $questions.each((index, element) => {
                let $question = jQuery(element);
                $question.append(`<div class="totalpoll-question-number">${index + 1} ${this.poll.config['i18n']['of']} ${$questions.length}</div>`);
                $question.on('change', 'input[type="checkbox"], input[type="radio"]', (event) => {
                    this.refreshButtons();
                });
            });

            this.setSlide(0);

            // Move to custom fields if there are errors
            if (this.$slides.last().find('.totalpoll-form-field-errors-item').length) {
                this.setSlide(this.$slides.length - 1);
            }
        }

        //@END-PRO
    }

    class OneClickBehaviour implements Behaviour {
        //@PRO
        constructor(poll: Poll) {
            var $voteButton = poll.element.find('.totalpoll-buttons-vote');
            $voteButton.hide();
            poll.element.find('.totalpoll-question input[type="checkbox"], .totalpoll-question input[type="radio"]').on('change', function (event) {
                $voteButton.prop('disabled', false).click();
            });
        }

        destroy() {
        }

        //@END-PRO
    }

    class ScrollUpBehaviour implements Behaviour {
        constructor(poll: Poll) {
            if (poll.isViaAjax() && !poll.isViaAsync()) {
                setTimeout(() => {
                    jQuery('html, body').animate({scrollTop: poll.element.offset().top - 100}, 1000);
                }, 200);
            }
        }

        destroy() {
        }
    }

    class EmbedResizingBehaviour implements Behaviour {
        public listener;

        constructor(public poll) {
            this.listener = (event) => this.receiveRequest(event);
            window.addEventListener("message", this.listener, false);
        }

        destroy() {
            window.removeEventListener("message", this.listener);
        }

        postHeight() {
            top.postMessage({
                totalpoll: {
                    id: this.poll.id,
                    action: 'resizeHeight',
                    value: jQuery(document.body).height()
                }
            }, '*');
        }

        receiveRequest(event) {
            if (event.data.totalpoll && event.data.totalpoll.id === this.poll.id && event.data.totalpoll.action === 'requestHeight') {
                this.postHeight();
            }
        }
    }

    class SelectionBehaviour implements Behaviour {
        constructor(private poll: Poll) {
            poll.element.find('.totalpoll-question input[type="checkbox"], .totalpoll-question input[type="radio"]').on('change', function (event) {
                var valid = false;
                var $this = jQuery(this);
                var $question = $this.closest('.totalpoll-question');
                var $otherChoice = $question.find('.totalpoll-question-choices-other');
                var $otherChoiceField = $otherChoice.find('input[type="text"]');
                var minSelection = parseInt($question.attr('totalpoll-min-selection')) || 0;
                var maxSelection = parseInt($question.attr('totalpoll-max-selection')) || 1;

                $question.find('.totalpoll-question-choices-item').removeClass('totalpoll-question-choices-item-checked');
                $question.find('.totalpoll-question-choices-item:has(input:checked)').addClass('totalpoll-question-choices-item-checked');

                var $checked = $question.find('input[type="checkbox"]:checked, input[type="radio"]:checked');
                var $unchecked = $question.find('input[type="checkbox"]:not(:checked), input[type="radio"]:not(:checked)');
                if (maxSelection == 1) {
                    valid = Boolean($checked.length) || minSelection == 0;
                } else if ($checked.length >= maxSelection) {
                    $unchecked.prop('disabled', true);
                    valid = true;
                    if ($otherChoiceField.length && !$otherChoiceField.val().trim() && !$this.parents('.totalpoll-question-choices-other').length) {
                        $otherChoice.hide();
                    }
                } else {
                    $unchecked.prop('disabled', false);
                    valid = $checked.length >= minSelection;
                    if ($otherChoiceField.length && !$otherChoiceField.val().trim()) {
                        $otherChoice.show();
                    }
                }

                $question.attr('totalpoll-valid-selection', valid ? 'true' : 'false');

                $question.find('.totalpoll-question-choices-item').removeClass('totalpoll-question-choices-item-disabled');
                $question.find('.totalpoll-question-choices-item:has(input:disabled)').addClass('totalpoll-question-choices-item-disabled');
            });
            poll.element.find('.totalpoll-question input[type="text"]').on('focus', function (event) {
                jQuery(this).closest('label').find('input[type="checkbox"], input[type="radio"]').prop('checked', true).change();
            });
            poll.element.find('.totalpoll-question input[type="text"]').on('blur', function (event) {
                jQuery(this).closest('label').find('input[type="checkbox"], input[type="radio"]').prop('checked', Boolean(this.value.trim())).change();
            });

            poll.element.find('.totalpoll-question input[type="checkbox"], .totalpoll-question input[type="radio"]').change();
        }

        destroy() {
        }
    }

    class ModalBehaviour implements Behaviour {
        public modal = new Modal();

        constructor(private poll: Poll) {
            jQuery(poll.element).on('click', '[totalpoll-modal]', (event) => {
                var selector = jQuery(event.currentTarget).attr('totalpoll-modal');
                if (selector) {
                    this.modal.setContent(jQuery(selector).clone());
                }

                this.modal.show();

                event.preventDefault();
            });
        }


        destroy() {
            this.modal.remove();
        }
    }

    class reCaptchaBehaviour implements Behaviour {
        readonly invisible: Boolean;
        readonly widget;
        private $form;
        private $recaptcha;
        private valid: Boolean = false;

        constructor(private poll: Poll) {
            this.$recaptcha = poll.element.find('.g-recaptcha');
            this.$form = poll.element.find('form');
            this.invisible = this.$recaptcha.data('size') === 'invisible';

            this.widget = this.invisible ? this.$form.find('[type="submit"][value="vote"]').get(0) : this.$recaptcha.get(0);

            // Invisible
            if (this.invisible) {
                this.$form.on('submit', (event) => this.validate(event));
                window['grecaptcha'].ready(() => this.render());
            } else if (this.poll.isViaAjax()) {
                this.render();
            }
        }

        destroy() {
            this.$recaptcha.remove();
        }

        render() {
            window['grecaptcha'].render(this.widget, {
                    sitekey: this.$recaptcha.data('sitekey'),
                    callback: () => {
                        this.valid = true;
                        if (this.invisible) {
                            this.$form.submit();
                        }
                    }
                }
            );
        }

        validate(event) {
            if (!this.valid) {
                event.preventDefault();
            }
        }
    }

    class CountUpBehaviour implements Behaviour {
        constructor(private poll: Poll) {
            poll.element.find('[totalpoll-count-up]').each((index, element) => {
                // Prepare arguments
                let $element = jQuery(element);
                let countTo = Number($element.attr('totalpoll-count-up'));
                let format = $element.attr('totalpoll-count-format') || "{{counter}}";
                let pad = Number($element.attr('totalpoll-count-pad')) || 2;
                // Start
                this.start($element, countTo, format, pad);
            });
        }

        destroy() {

        }

        start(el, countTo, format, pad = 2) {
            jQuery({counter: 0, format, pad, el})
                .animate({counter: countTo}, {
                    duration: 900,
                    step: this.step,
                    complete: this.step
                });
        }

        step() {
            let formatedCounter = this['format'].replace('{{counter}}', CountUpBehaviour.padStart(Math.round(this['counter']), this['pad']));
            this['el'].text(formatedCounter);
        }

        static padStart(number, targetLength, padString = "0") {
            targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
            padString = String(typeof padString !== 'undefined' ? padString : ' ');
            if (number.length >= targetLength) {
                return String(number);
            } else {
                targetLength = targetLength - number.length;
                if (targetLength > padString.length) {
                    padString += padString['repeat'](targetLength / padString.length); //append to original to ensure we are longer than needed
                }
                return padString.slice(0, targetLength) + String(number);
            }
        }
    }

    /**
     * Transitions
     */
    abstract class Transition {
        constructor(public element, public duration: number) {
            this.element = jQuery(element);
        }

        getDuration() {
            return Number(this.duration);
        }

        getElement() {
            return this.element;
        }

        abstract in(callback?: Function, duration?: number);

        abstract out(callback?: Function, duration?: number);
    }

    class SimpleTransition extends Transition {
        in(callback?: Function, duration: number = this.getDuration()) {
            this.getElement().css({'visibility': 'visible', 'display': 'inherit'});
            if (callback) {
                callback();
            }
        }

        out(callback?: Function, duration: number = this.getDuration()) {
            this.getElement().css('visibility', 'hidden');
            if (callback) {
                callback();
            }
        }
    }

    class FadeTransition extends Transition {
        in(callback?: Function, duration: number = this.getDuration()) {
            this.getElement().fadeIn(duration, callback);
        }

        out(callback?: Function, duration: number = this.getDuration()) {
            this.getElement().fadeTo(duration, 0.2, callback);
        }
    }

    class SlideTransition extends Transition {
        in(callback?: Function, duration: number = this.getDuration()) {
            this.getElement().slideDown(duration, callback);
        }

        out(callback?: Function, duration: number = this.getDuration()) {
            this.getElement().slideUp(duration, callback);
        }
    }

    export class Hooks {
        static addAction(event, callback) {
            jQuery(TotalPoll).on(event, function (event) {
                let payload = [].slice.call(arguments).splice(1);
                callback.apply(event, payload);
            });
        }

        static doAction(event, payload) {
            jQuery(TotalPoll).triggerHandler(event, payload);
        }
    }

    /**
     * Poll
     */
    export class Poll {
        public behaviours: any = {};
        public config: any = {};
        public id;
        public screen: any = '';
        public transition: Transition;

        constructor(public element, private viaAjax = false, private viaAsync = false) {
            this.id = element.attr('totalpoll');
            this.config = JSON.parse(element.find('[totalpoll-config]').text());
            this.screen = element.attr('totalpoll-screen');

            if (TotalPoll.Polls[this.id]) {
                // Destroy the old instance
                TotalPoll.Polls[this.id].destroy();
            }

            TotalPoll.Polls[this.id] = this;
            element.data('poll', this);

            // Selection
            this.behaviours['selection'] = new SelectionBehaviour(this);

            // Modal
            if (this.config['behaviours']['disableModal']) {
                this.element.find('[totalpoll-modal]').remove();
                this.element.find('.has-thumbnail').removeClass('has-thumbnail');
            } else {
                this.behaviours['modal'] = new ModalBehaviour(this);
            }

            // Scroll up
            if (this.config['behaviours']['scrollUp']) {
                this.behaviours['scrollUp'] = new ScrollUpBehaviour(this);
            }

            // One-click
            if (this.config['behaviours']['oneClick']) {
                this.behaviours['oneClick'] = new OneClickBehaviour(this);
            }

            // Questions slider
            if (this.config['behaviours']['slider']) {
                this.behaviours['slider'] = new SliderBehaviour(this);
            }

            // Count up
            if (this.config['behaviours']['countUp']) {
                this.behaviours['countUp'] = new CountUpBehaviour(this);
            }

            // Transition
            let transition = this.config['effects']['transition'];
            let container = this.element.find('.totalpoll-container');

            if (transition == 'fade') {
                this.transition = new FadeTransition(container, this.config['effects']['duration']);
            } else if (transition == 'slide') {
                this.transition = new SlideTransition(container, this.config['effects']['duration']);
            } else {
                this.transition = new SimpleTransition(container, this.config['effects']['duration']);
            }

            // reCaptcha
            if (window['grecaptcha'] && element.find('.g-recaptcha').length) {
                this.behaviours['recaptcha'] = new reCaptchaBehaviour(this);
            }

            // Ajax
            if (this.config['behaviours']['async'] || this.config['behaviours']['ajax']) {
                this.behaviours['ajax'] = new AjaxBehaviour(this);
            }

            // Async
            if (this.config['behaviours']['async']) {
                this.behaviours['ajax'].load(element.attr('totalpoll-ajax-url'));
            }

            // Embed
            if (window.top !== window.self) {
                this.behaviours['embed'] = new EmbedResizingBehaviour(this);
                this.behaviours['embed'].postHeight();
            }

            Hooks.doAction('totalpoll/init', this);
        }

        destroy() {
            jQuery.each(this.behaviours, function (id, behaviour: Behaviour) {
                behaviour.destroy();
            });
            Hooks.doAction('totalpoll/destroy', this);
            // this.element.remove();
        }

        isViaAjax() {
            return this.viaAjax;
        }

        isViaAsync() {
            return this.viaAsync;
        }
    }
}

jQuery(function ($) {
    $('[totalpoll]').each(function () {
        new TotalPoll.Poll($(this));
    });
});
